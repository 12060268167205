import { VariantProps } from 'class-variance-authority';
import { useRef } from 'react';
import { useLocale, useNumberField } from 'react-aria';
import type { NumberFieldProps } from 'react-aria-components';
import { Group, Input } from 'react-aria-components';
import { useNumberFieldState } from 'react-stately';
import { twMerge } from 'tailwind-merge';
import { FieldLabel, FieldDescription, FieldError, FieldProps } from '../../atoms/field/field';
import { Pressable } from '../../atoms/pressable/pressable';
import { fieldLabel } from '../../electrons/field';
import { input, inputContainer } from '../../electrons/input';

interface _NumberFieldProps extends NumberFieldProps, FieldProps, VariantProps<typeof fieldLabel> {
  placeholder?: string;
  name?: string;
  className?: string;
}

function _NumberField({
  label,
  weightLabel,
  description,
  placeholder,
  errorMessage,
  name,
  className,
  ...props
}: _NumberFieldProps) {
  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, locale });
  const inputRef = useRef(null);

  const { labelProps, groupProps, inputProps, incrementButtonProps, decrementButtonProps } =
    useNumberField(
      {
        label,
        ...props,
      },
      state,
      inputRef,
    );

  return (
    <div className={className}>
      {label && (
        <FieldLabel weightLabel={weightLabel} {...labelProps}>
          {label}
        </FieldLabel>
      )}
      <Group className={twMerge(inputContainer(), 'max-w-[256px]')} {...groupProps}>
        <Pressable
          {...decrementButtonProps}
          className="border-grey-300 hover:bg-grey-100 rounded-l-full border-r py-2 pl-4 pr-3 transition-transform">
          -
        </Pressable>
        <Input
          className={twMerge(input(), 'block w-6')}
          {...inputProps}
          name={name}
          placeholder={placeholder}
          ref={inputRef}
        />
        <Pressable
          className="border-grey-300 hover:bg-grey-100 flex-shrink-0 rounded-r-full border-l py-2 pl-3 pr-4 transition-transform"
          {...incrementButtonProps}>
          +
        </Pressable>
      </Group>
      {errorMessage ? (
        <FieldError>{errorMessage}</FieldError>
      ) : (
        description && <FieldDescription>{description}</FieldDescription>
      )}
    </div>
  );
}

export { _NumberField as NumberField };
